@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *::-webkit-scrollbar {
    @apply hidden;
  }

  html {
    @apply scroll-smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-gray-900 dark:text-gray-50;
  }

  h1.inverse-color,
  h2.inverse-color,
  h3.inverse-color,
  h4.inverse-color,
  h5.inverse-color,
  h6.inverse-color {
    @apply text-gray-50 dark:text-gray-900;
  }

  h1 {
    @apply text-4xl font-extrabold;
  }

  h2 {
    @apply text-3xl font-bold;
  }

  h3 {
    @apply text-2xl font-bold;
  }

  h4 {
    @apply text-xl font-bold;
  }

  h5 {
    @apply text-lg font-bold;
  }

  h6 {
    @apply text-base font-bold;
  }

  a {
    @apply font-medium text-blue-600 dark:text-blue-500 hover:underline;
  }

  a[type="button"] {
    @apply hover:no-underline;
  }

  a.none {
    @apply text-inherit no-underline font-normal;
  }

  strong {
    @apply font-semibold text-gray-700 dark:text-gray-200;
  }

  strong.inverse-color {
    @apply text-gray-200 dark:text-gray-700;
  }

  u {
    @apply underline;
  }

  em {
    @apply italic;
  }

  p,
  div,
  li {
    @apply text-gray-700 dark:text-gray-200;
  }

  p.inverse-color,
  div.inverse-color,
  li.inverse-color {
    @apply text-gray-200 dark:text-gray-700;
  }

  p + p {
    @apply mt-3;
  }

  p.firstLetter {
    @apply first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-100 first-letter:me-3 first-letter:float-start;
  }

  p a {
    @apply font-medium text-blue-600 underline dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700 hover:no-underline;
  }

  hr {
    @apply h-px my-8 bg-gray-200 border-0 dark:bg-gray-700;
  }

  kbd {
    @apply px-1.5 py-1 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-400 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500;
  }

  kbd + kbd {
    @apply ms-1;
  }

  ul:has(ul),
  ol:has(ul),
  ul:has(ol),
  ol:has(ol) {
    @apply space-y-4;
  }
}

@layer components {
  .i-twoColumns {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-6;
  }

  .i-twoColumns + .i-twoColumns {
    @apply mt-6;
  }

  .i-background {
    --s: 200px;
    --c1: #1d1d1d;
    --c2: #4e4f51;
    --c3: #3c3c3c;

    background: repeating-conic-gradient(
          from 30deg,
          #0000 0 120deg,
          var(--c3) 0 180deg
        )
        calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
      repeating-conic-gradient(
        from 30deg,
        var(--c1) 0 60deg,
        var(--c2) 0 120deg,
        var(--c3) 0 180deg
      );
    background-size: var(--s) calc(var(--s) * 0.577);
  }

  .i-bg-white {
    @apply bg-white dark:bg-gray-900;
  }

  .i-border {
    @apply border border-gray-500 dark:border-gray-400;
  }

  .i-highlighted-heading {
    @apply text-blue-600 dark:text-blue-500;
  }

  .i-leading {
    @apply text-lg;
  }

  .i-glassmorphism {
    @apply bg-white/50 dark:bg-gray-900/50 shadow-lg backdrop-blur;
  }

  .i-text-stroke {
    -webkit-text-stroke: 2px rgb(17 24 39);
    color: transparent;
  }
  .i-text-stroke.dark {
    -webkit-text-stroke: 2px rgb(255 255 255);
  }

  .i-animate-drawing {
    fill: none;
    stroke: currentColor;
    stroke-width: 1;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: drawText 2s linear forwards;
  }
}
